/* main */
/*
  INDEX
  1. Bottom Nav
  2. Settings Page
  3. Device Settings Icon
  4. About Page
  5. Scan Page
  6. Settings
  7. Settings Slider
  8. History Page
*/
body {
  background-color: #32355c !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 500 !important;
  color: white !important;
}

a:hover {
  text-decoration: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.welcome-image {
  width: 68%;
}

.footer-icon {
  font-size: 1rem;
  font-weight: 600;
}

.main-app {
  height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.vh-95 {
  height: 95vh;
}

.main-background-color {
  background-color: #32355c !important;
}

h5 {
  font-family: "Raleway", sans-serif !important;
  font-weight: 900 !important;
  font-size: 1.4em !important;
}

h6 {
  font-weight: 700 !important;
  font-size: 1.1em !important;
}

h4 {
  font-family: "Raleway", sans-serif !important;
  font-weight: 900 !important;
  font-size: 1.5em !important;
}

label {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700 !important;
}

body .history {
  overflow-y: auto;
}

/* 1. Bottom Nav */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #383837 !important;
  margin-top: 70px;
}

.bottom-nav a:hover {
  text-decoration: none;
}

.inactive {
  color: #383837 !important;
}

.active {
  color: #0693c8 !important;
  text-decoration: none !important;
}

/* 2. Settings Page */
.custom-control-label::before {
  background-color: white !important;
  border: white solid 1px !important;
}

.custom-control-label::after {
  background-color: #0693c8 !important;
  border: white solid 1px !important;
}

.toggle {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.1em !important;
}

.sm-text {
  font-size: 0.8rem !important;
}

.settingsLinks {
  font-size: 1.2em !important;
  font-weight: 700 !important;
  padding-top: 5px;
}

.notes {
  font-size: 0.8em !important;
}

/* 3. Device Settings Icon */
.custom-icon {
  height: 45px;
}

/* 4. About Page */
.back {
  font-family: "Lato", sans-serif !important;
  font-weight: 700 !important;
}

/* 5. Scan Page */
.btn-primary {
  background-color: #0693c8 !important;
  border-color: #0693c8 !important;
  margin-bottom: 30px;
  font-weight: 700 !important;
}

.homeNote {
  font-size: 1.2em;
}

.home-page {
  height: 90%;
}

/* 6. Settings */
.accordion .card,
.accordion .card-header {
  background-color: inherit;
  border: none;
  border-radius: inherit;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  -ms-border-radius: inherit;
  -o-border-radius: inherit;
}

.accordion .card-item {
  border-bottom: 1px solid !important;
}

.accordion .card-header {
  padding: 0.75rem 0.25rem;
}

.accordion .faq-item {
  border-bottom: inherit;
  border-top: inherit;
  border-left: inherit;
  border-right: inherit;
}

.accordion .faq-item-header {
  border-bottom: 1px solid;
}

/* 7. Settings Slider */
.active-slider .custom-control-label::before {
  background-color: #0693c8 !important;
  border: #0693c8 !important;
}

.active-slider .custom-control-label::after {
  background-color: white !important;
  border: white !important;
}

/* 8. History Page */
.eventHistory {
  font-weight: 700 !important;
  font-size: 1.2em;
}

.dateHistory {
  font-size: 1em;
}